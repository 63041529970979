import type { InvoiceDto } from '../../dtos/invoice-list.dto';
import type { BaseDataTableUrlParamsModel } from '../base/base-data-table-url-params.model';

export enum ReportType {
  CSV = 'csv',
  EXCEL = 'xlsx',
  PDF = 'pdf',
}

export interface ExportReportParams {
  url: string;
  type: ReportType;
  filename: string;
  successMessage: string;
  errorMessage: string;
  options?: BaseDataTableUrlParamsModel<InvoiceDto>;
}
